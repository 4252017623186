import * as React from "react";
import "./QuickActions.sass";
import { Link } from "gatsby";
import arrow from "../images/arrow_white.png";
import phone from "../images/phone_icon.png";
import human from "../images/human_icon.png";
import { telephonePrest, meetingPrest } from "../helpers";

const QuickActions = () => {
  return (
    <div id="action-container">
      <div className="action-column" id="action-text">
        <p id="sub">Démarrez dès maintenant</p>
        <h3>Entretien avec un avocat</h3>
        <p id="action-description">
          Posez vos questions à un avocat par téléphone ou en personne en fixant
          un rendez-vous selon vos besoins.
        </p>
      </div>
      <Link to="/checkout" state={telephonePrest()}>
        <div className="action-column quick-action">
          <div>
            <div id="title-holder">
              <img src={phone} alt="phone-icon"></img>
              <h3>Entretien téléphonique</h3>
            </div>
            <p>
              Prenez rendez-vous avec un avocat pour un entretien téléphonique
              de 30 à 40 minutes.
            </p>
          </div>

          <div id="price-container">
            <h3 className="price">CHF 100.-</h3>
            <img src={arrow} alt="arrow-icon"></img>
          </div>
        </div>
      </Link>

      <Link to="/checkout" state={meetingPrest()}>
        <div className="action-column quick-action">
          <div>
            <div id="title-holder">
              <img src={human} alt="avatar-icon"></img>
              <h3>Entretien personnel</h3>
            </div>
            <p>
              Prenez rendez-vous avec un avocat pour un entretien personnel de
              45 à 60 minutes.
            </p>
          </div>

          <div id="price-container">
            <h3 className="price">CHF 150.-</h3>
            <img src={arrow} alt="arrow icon"></img>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default QuickActions;
